import styled from 'styled-components';

// -------------------------------------------------
// HEADER CSS
// -------------------------------------------------

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: left;
  padding: 12px;
  background-color: white;
  height: 60px;
  align-items: center;
  `;

export const LogoText = styled.div`
  font-family: 'Questrial', sans-serif;
  margin-left: 5%;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
  line-height: 1.2;
  letter-spacing: 3px;
`;

// -------------------------------------------------
// MID CSS
// -------------------------------------------------
export const MainImage = styled.div`
display: flex;
justify-content: center;
align-items: top;
 img {
      width: 75%;
      height: 500px;
      object-fit: cover;
      object-position: center;
    }
`;

export const InfoCard = styled.div`
    font-family: 'Questrial', sans-serif;

  margin: auto;
  max-width: 50%;
  height: 80%;
  transform: translateY(-6rem);
  background-color: #FFF;

  .info{
    padding-left: 40px;
     padding-bottom: 10px; 
     padding-top: 10px;
    }
    
    @media (max-width: 768px) {
        max-width: 90%;
    
    
    .info {
        display: flex;
        flex-direction: column;
        /* padding: 1rem 0 0 10px; */
        padding-bottom: 10px; 
    }
    .h3 {
        font-weight: 400;
        font-size: 20px;
    }
    
    .h5{
        font-weight: 400;
    }
  }
    
`;


// -------------------------------------------------
// FOOTER CSS
// -------------------------------------------------

export const FooterWrapper = styled.div`
  font-family: 'Questrial', sans-serif;
   bottom: 0;
   width: 100%;
   height: 60px;  
   background-color: white;
   color: #000;
   text-align: center;
   line-height: 60px;  
   font-size: 9px;
   letter-spacing: 0.2px;

   @media (max-width: 768px) {
    font-size: 8px;
   
   }
`;

