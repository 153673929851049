import './App.css';
import { Collage } from './Components/Collage';
import { Footer } from './Components/Footer';
import Header from './Components/Header';



function App() {
  return (
    <>
      <Header />
      <Collage />
      <Footer />
    </>
  );
}

export default App;
